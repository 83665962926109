<template>
  <div class='flex flex-col lg:flex-row lg:justify-center items-start lg:items-center gap-y-4 lg:gap-x-4'>
    <div
      class='important-lecture px-8 pt-10 relative'
      style='background: rgb(71,53,100); background: linear-gradient(360deg, rgba(40,29,62,1) 0%, rgba(71,53,100,1) 100%); color: #FBF8FF;'>
      <h3 class='uppercase font-semibold text-2xl'>3월 12일</h3>
      <h4 class='font-light text-2xl' style='color: #D5D3D9;'>10:30 ~ 11:30</h4>
      <h5 class='absolute text-lg' style='color: #D5D3D9;'>Room 1,2,3</h5>
      <h1 class='mt-20 font-bold text-3xl'>Plenary Lecture</h1>
      <h4 class='mt-4 font-semibold text-lg leading-5'>Comprehensive Perspective on Obesity in Koreans</h4>
      <h4 class='mt-16 font-normal text-2xl'>박혜순</h4>
      <h5 class='text-base' style='color: #D5D3D9;'>울산의대</h5>
      <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/plenary_speaker.png' class='absolute bottom-0 w-44 lg:ml-20' />
    </div>
    <div
      class='important-lecture px-8 pt-10 relative'
      style='background: rgb(24,32,36); background: linear-gradient(360deg, rgba(24,32,36,1) 0%, rgba(39,68,54,1) 100%); color: #F9FEFC;'>
      <h3 class='uppercase font-semibold text-2xl'>3월 12일</h3>
      <h4 class='font-light text-2xl' style='color: #D5D3D9;'>15:30 ~ 16:00</h4>
      <h5 class='absolute text-lg' style='color: #D5D3D9;'>Room 1</h5>
      <h1 class='mt-20 font-bold text-3xl'>Keynote Lecture 1</h1>
      <h4 class='mt-4 font-semibold text-lg leading-5'>Stop the natural history of type 2 diabetes</h4>
      <h4 class='mt-20 pt-1 font-normal text-2xl'>권혁상</h4>
      <h5 class='text-base' style='color: #939393;'>가톨릭의대</h5>
      <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/keynote1_speaker.png' class='absolute bottom-0 w-56 lg:ml-16' />
    </div>
    <div
      class='important-lecture px-8 pt-10 relative'
      style='background: rgb(24,32,36); background: linear-gradient(360deg, rgba(24,32,36,1) 0%, rgba(39,68,54,1) 100%); color: #F9FEFC;'>
      <h3 class='uppercase font-semibold text-2xl'>3월 12일</h3>
      <h4 class='font-light text-2xl' style='color: #D5D3D9;'>15:30 ~ 16:00</h4>
      <h5 class='absolute text-lg' style='color: #D5D3D9;'>Room 3</h5>
      <h1 class='mt-20 font-bold text-3xl'>Keynote Lecture 2</h1>
      <h4 class='mt-4 font-semibold text-lg leading-5'>비만치료에서 간헐적 단식에 대한 체계적 문헌고찰과 다학제 권고안</h4>
      <h4 class='mt-16 font-normal text-2xl'>김경곤</h4>
      <h5 class='text-base' style='color: #939393;'>가천의대</h5>
      <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/keynote2_speaker.png' class='absolute bottom-0 w-56 lg:ml-16' />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImportantLectures',
  components: {
  },
}
</script>

<style lang='scss' scoped>
  .important-lecture {
     width: 100%;
     height: 40rem;
  }

  @media (min-width: 1024px) {
    .important-lecture {
       width: 28rem;
    }
  }

</style>
