<template>
  <div>
    <welcome-conference-banner class='lg:pt-16 lg:pb-40'
      background-color='#F9F6F9' />
    <div class='relative'>
       <content-background-cover
          :background-color='backgroundColor'
          :top-offset='1'
          class='z-0'
          style='background: rgb(6,1,12); background: linear-gradient(360deg, rgba(6,1,12,1) 0%, rgba(34,32,36,1) 100%);'/>
      <important-dates />
      <important-lectures class='lg:pt-64 pb-16 lg:pb-36' />
    </div>
    <welcome-sponsors-carousel class='mt-24' />
  </div>
</template>

<script>

import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'
import ImportantLectures       from '@/components/welcome/ImportantLectures.vue'
import ImportantDates          from '@/components/welcome/ImportantDates.vue'
import WelcomeSponsorsCarousel from '@/components/welcome/WelcomeSponsorsCarousel.vue'
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'


export default {
  name: 'Main',
  components: {
    WelcomeConferenceBanner,
    ImportantLectures,
    ImportantDates,
    WelcomeSponsorsCarousel,
    ContentBackgroundCover,
  },
  computed: {
    backgroundColor () {
      return '#000000'
    }
  }
}
</script>
