<template>
  <div class='w-full flex flex-col lg:flex-row lg:justify-center items-start lg:items-stretch gap-y-4 lg:gap-x-4 p-8 lg-p-0'>
    <div 
      class='important-date-block h-40 lg:h-72 flex flex-col justify-between items-start cursor-pointer hover:shadow-lg'
      style='background: rgb(236,228,249);background: linear-gradient(360deg, rgba(238,238,238,1) 0%, rgba(236,228,249,1) 100%); color: #513A76;'
      @click='goToPreregistrationInfo'>
      <div class='pl-8 pt-8'>
        <h2 class='text-2xl lg:text-4xl'>사전등록</h2>
        <h3 class='month uppercase lg:mt-2 lg:text-xl'>3월 7일 마감</h3>
      </div>
      <h3 class='month uppercase lg:text-xl font-light text-left lg:text-right w-full px-8 pb-8 flex flex-row justify-start lg:justify-end items-center gap-x-1'>
        바로가기
        <arrow-narrow-right-icon class='inline-block' />
      </h3>
    </div>
    <div 
      class='important-date-block h-40 lg:h-72 flex flex-col justify-between items-start'
      style='background: rgb(236,228,249);background: linear-gradient(360deg,  rgba(238,238,238,1) 0%, rgba(236,228,249,1) 100%); color: #513A76;'>
      <div class='pl-8 pt-8'>
        <h2 class='text-2xl lg:text-4xl'>초록접수</h2>
        <h3 class='month uppercase lg:mt-2 lg:text-xl'>접수 마감</h3>
      </div>
    </div>
    <div 
      class='important-date-block h-40 lg:h-72 flex flex-col justify-between items-start cursor-pointer hover:shadow-lg'
      style='background: rgb(236,228,249);background: linear-gradient(360deg, rgba(238,238,238,1) 0%, rgba(236,228,249,1) 100%); color: #513A76;'
      @click='goToPrograms'>
      <div class='pl-8 pt-8'>
        <h2 class='text-2xl lg:text-4xl'>프로그램</h2>
      </div>
      <h3 class='month uppercase lg:text-xl font-light text-left lg:text-right w-full px-8 pb-8 flex flex-row justify-start lg:justify-end items-center gap-x-1'>
        바로가기
        <arrow-narrow-right-icon class='inline-block' />
      </h3>
    </div>
  </div>
</template>

<script>
import { ArrowNarrowRightIcon } from '@vue-hero-icons/outline'

export default {
  name: 'ImportantDates',
  components: {
    ArrowNarrowRightIcon,
  },
  methods: {
    goToPreregistrationInfo () {
      window.open('https://ksso2022.kossoasc.org/registration/reg.html', '_blank')
    },
    goToPrograms () {
      this.$router.push({name: 'Programs'}).catch(() => {})
    }
  }
}
</script>

<style lang='scss' scoped>
  .important-date-block {
     width: 100%;
  }

  .date-title {
      font-size: 1rem;
    }

  .important-dates-container {
    position: static;
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    .important-date-block {
       width: 20rem;
    }

    .date-title {
      font-size: 1.25rem;
    }

    .important-dates-container {
      position: absolute;
      margin-top: -10rem;
    }

  }

</style>
